.align-content-center{
    width: 25%;
    align-items: center;
    position: sticky;
    left: 38%;
}

.text-center1{
    margin-top: 30px;
    text-align: center;
}

.text-center2 p{
    top: 10px;
    position: static;
    left: 20px;
}


@media (max-width: 768px){
    .align-content-center{
        position: fixed;
        top: 10%;
        left: 10%;
        width: 80%;
    }

    
}

.admin_table_section{
    padding-bottom: 20px;
}

.table-container {
    overflow-x: auto;
}

#adminTable{
    
    display:table;
    width: auto;
    padding: 30px 100px;
    -webkit-box-shadow: 0 0 15px 10px rgba(0, 0, 0, 0.07);
            box-shadow: 0 0 15px 10px rgba(0, 0, 0, 0.07);
    width: 100%;
    border-collapse: collapse;
}

.admin_table_section h2{

    color: #007bff;
    text-align: center;
    padding-bottom: 20px;
}

#adminTable th, #adminTable td {
    padding: 8px;
    border: 1px solid #ddd;
    text-align: left;
}

#adminTable th {
    background-color: #007bff;
    padding-bottom: 10px;
}


#adminTable tbody tr:nth-child(even) {
    background-color: #f2f2f2;
}


#adminTable tbody tr:hover {
    background-color: #ddd;
}

#adminTable button{
    background-color: #007bff;
    padding: 5px 5px;
}

.admin_form h4{
    font-size: 35px;
    text-align: center;
    color: #007bff;
    padding-top: 15px;
    
}

.admin_form {
    
    display:block;
    width: auto;
    padding: 30px 100px;
    -webkit-box-shadow: 0 0 15px 10px rgba(0, 0, 0, 0.07);
            box-shadow: 0 0 15px 10px rgba(0, 0, 0, 0.07);
}

.admin_form label{
    font-size: 20px;
    color: black;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-style: inherit;
}


.admin_form .form-control {
   
    background-color: transparent;
    border: 1px solid #007bff;
    height: 45px;
    outline: none;
    -webkit-box-shadow: none;
            box-shadow: none;
    border-radius: 5px;
    padding: 0 15px;
}

.admin_form .form-control::-webkit-input-placeholder {
    color: #007bff;
}

.admin_form #inputDate{
    color: #007bff;
}

.admin_form #inputDate input{
    color: #007bff;
}

.admin_form button.btn{
    background-color: #252525;
    color: #ffffff;
    padding: 10px 45px;
    border-radius: 5px;
    margin-top: 10px;
    text-transform: uppercase;
}



.appointments_table_section {
    padding-bottom: 20px;
}

.table-container {
    overflow-x: auto;
}

#appointmentsTable {
    display: table;
    width: auto;
    padding: 30px 100px;
    -webkit-box-shadow: 0 0 15px 10px rgba(0, 0, 0, 0.07);
    box-shadow: 0 0 15px 10px rgba(0, 0, 0, 0.07);
    width: 100%;
    border-collapse: collapse;
    padding-bottom: 100px;
}

.appointments_table_section h2 {
    color: #007bff;
    text-align: center;
    padding-bottom: 20px;
}

#appointmentsTable th,
#appointmentsTable td {
    padding: 8px;
    border: 1px solid #ddd;
    text-align: left;
}

#appointmentsTable th {
    background-color: #007bff;
    padding-bottom: 10px;
}

#appointmentsTable tbody tr:nth-child(even) {
    background-color: #f2f2f2;
}

#appointmentsTable tbody tr:hover {
    background-color: #ddd;
}

#appointmentsTable button {
    background-color: #007bff;
    padding: 5px 5px;
}

.date-filters, .filter1 {
    display: flex;
    align-items: center;
}


.filter {
    margin-right: 20px; /* Adjust spacing between elements */
}


@media (max-width: 768px) {
   
    
    input[type="date"]
     {
        width: 90%;
    }

    select{
        width: 80%;
        margin-bottom: 20px;
    }

    .date-filters{
        flex-direction: row;
    }
}

.floating-icons {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 1000;
}

.floating-icon {
  background-color: #25d366; /* WhatsApp green */
  color: white;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
}

.floating-iconup {
  background-color: #8c6dfc; /* WhatsApp green */
  color: white;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
}

.floating-icon.phone {
  background-color: #34b7f1; /* Phone blue */
}

.floating-icon:hover {
  background-color: #128c7e;
}

.floating-icon.phone:hover {
  background-color: #0e76a8;
}

/* Adjustments for mobile devices */
@media (max-width: 768px) {
  .floating-icons {
    bottom: 40px;
    right: 10px;
    gap: 5px;
  }

  .floating-icon {
    width: 40px;
    height: 40px;
  }
}
